import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, TextField, Button, Divider } from '@mui/material';
import VendorAppBar from '../Component/VendorAppBar';
import './Profile.css';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaIndustry, FaMap, FaBuilding, FaUserAlt} from 'react-icons/fa';
// import { MdLocationOn, MdBusiness } from 'react-icons/md';
import axios from 'axios';
import api from '../Services/api';
import {Col} from "react-bootstrap";



const Profile = () => {
  const [editMode, setEditMode] = useState(false);
  const [originalData, setOriginalData] = useState({});
  const [contactData, setContactData] = useState({
    vendorId:'',
    vendorName: ' ',
    vendorAddress: '',
    vendorEmailId: '',
    vendorPhoneNumber: '',
    stateId: '',
    placeName: '',
    unit: '',
    contactPersonName: '',
    contactPersonPhoneNumber: '',
    contactPersonEmailId: ''
   
  });
  const [clientData, setClientData] = useState({
    companyName: '',
    companyAddress: '',
    companyEmailId: '',
    companyPhoneNumber: '',
    contactPersonName: '',
    contactPersonPhoneNumber: '',
    contactPersonEmailId: '',
    natureOfBusiness: '',
    stateId: '',
    placeName: '',
    unit:  '',
  });
  const [companyData, setCompanyData] = useState({
    companyName: '',
    companyAddress: '',
    companyEmailId: '',
    companyPhoneNumber: '',
    contactPersonName: '',
    contactPersonPhoneNumber: '',
    contactPersonEmailId: '',
    natureOfBusiness: '',
    stateId: '',
    placeName: '',
    unit:  '',
  });

 

  useEffect(() =>{
    api.vendor.findvendor()
    .then(response =>{
      const temp = response.data.filtterData;
      console.log("temp",temp);

      setContactData(temp);
      setOriginalData(temp);
    })
    .catch(error => console.error('error fetching profile data:', error))
  }, []);

  

  useEffect(() => {
    const requestData = contactData.clientId;
    
     console.log("id:",requestData);
  
    api.vendor.findOne(requestData)
    .then(response => {
      // console.log("masterdata:", response);
      const temp1 = response.data;
    console.log("temp1",temp1);
       setClientData(temp1)
       console.log("setcompany:",contactData);
       setOriginalData(temp1)
    })
    .catch(error => console.error('error fetching company data', error));
  }, [contactData]);
  useEffect(() => {
    const requestData1 = contactData.companyId;
    
     console.log("id1:",requestData1);
  
    api.vendor.findOne(requestData1)
    .then(response => {
      // console.log("masterdata:", response);
      const temp2 = response.data;
    console.log("temp2",temp2);
       setCompanyData(temp2)
       setOriginalData(temp2)
    })
    .catch(error => console.error('error fetching company data', error));
  }, [contactData]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
   
  };

  
 
  const handleSave = ()=>{
    setEditMode(false);
    let id = contactData.vendorId;
    api.vendor.updateOne(id, contactData)
    .then(setContactData => {
      window.alert("Edited Successfully")
    })
    .catch(error =>{
      window.alert("Error occurred while updating")
    });
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
<VendorAppBar/>
    
 
        <Card className="d-md-flex m-5" style={{border:'1px solid gray'}}>
        <Col >
        <CardContent className=" p-4" >
          <div>
            <Typography variant="h5" gutterBottom>
              Client Details
            </Typography>
            <Divider  style={{ fontWeight: 'bold'}} />
            {/* Company Details */}
            
            <Typography className="mt-2" variant="body2" color="textSecondary" component="div">
            <FaBuilding /> Name: {clientData?.data?.companyName}
            </Typography>
            <Typography  className="mt-2" variant="body2" color="textSecondary" component="div">
            <FaMapMarkerAlt /> Address: {clientData?.data?.companyAddress}
            </Typography>
            <Typography className="mt-2" variant="body2" color="textSecondary" component="div" >
              <FaEnvelope /> Email: {clientData?.data?.companyEmailId}
            </Typography>
            <Typography className="mt-2" variant="body2" color="textSecondary" component="div" >
              <FaPhone /> PhoneNumber: {clientData?.data?.companyPhoneNumber}
            </Typography>
            <Typography className="mt-2" variant="body2" color="textSecondary" component="div" >
              <FaMapMarkerAlt /> Location: {clientData?.data?.placeName}
            </Typography>
           
            </div>
          
            <br></br>
   <div>
    <Typography variant="h6" gutterBottom>
        Contact Person Details
    </Typography>
    <Divider />
    <Typography className="mt-2" variant="body2" color="textSecondary" component="div">
    <FaUserAlt /> Name: {clientData?.data?.contactPersonName}
    </Typography>
    <Typography className="mt-2" variant="body2" color="textSecondary" component="div" >
    <FaEnvelope />  Email: {clientData?.data?.contactPersonEmailId}
    </Typography>
    <Typography className="mt-2" variant="body2" color="textSecondary" component="div" >
    <FaPhone />  PhoneNumber: {clientData?.data?.contactPersonPhoneNumber}
    </Typography>
    </div>
 
</CardContent>
</Col>

          <Col className="bg-dark p-4">
        
               <div>
            <Typography variant="h5" gutterBottom className="text-white">
              Company Details
            </Typography>
         
            <Typography className="mt-2 text-white" variant="body2"  component="div">
            <FaBuilding /> Name: {companyData?.data?.companyName}
            </Typography>
            <Typography  className="mt-2 text-white" variant="body2"  component="div">
            <FaMapMarkerAlt /> Address: {companyData?.data?.companyAddress}
            </Typography>
            <Typography className="mt-2 text-white" variant="body2"  component="div" >
              <FaEnvelope /> Email: {companyData?.data?.companyEmailId}
            </Typography>
            <Typography className="mt-2 text-white" variant="body2" component="div" >
              <FaPhone /> PhoneNumber: {companyData?.data?.companyPhoneNumber}
            </Typography>
             <Typography className="mt-2 text-white" variant="body2"  component="div" >
              <FaMapMarkerAlt /> Location: {companyData?.data?.placeName}
            </Typography> 
           

           
            </div>
           <br></br>
            <div>
    <Typography className="text-white" variant="h6" gutterBottom>
        Contact Person Details
    </Typography>
   
    <Typography className="mt-2 text-white" variant="body2" component="div">
    <FaUserAlt /> Name: {companyData?.data?.contactPersonName}
    </Typography>
    <Typography className="mt-2 text-white" variant="body2"  component="div" >
    <FaEnvelope />  Email: {companyData?.data?.contactPersonEmailId}
    </Typography>
    <Typography className="mt-2 text-white" variant="body2"  component="div" >
    <FaPhone />  PhoneNumber: {companyData?.data?.contactPersonPhoneNumber}
    </Typography>
    </div>
   
          </Col>
          <div className='vr'><i class="bi bi-arrows text-warning p-2" style={{position:'relative',top:'150px',right:'28px',fontSize:'40px',backgroundColor:'white',borderRadius:'50%'}}></i></div>
     
        
        <Col className="text-center">
        
          <CardContent className="card-content bg-dark  p-5  h-100 " >
            <Typography variant="h6" gutterBottom className='text-white' >
              Vendor Contact  details
            </Typography>
            <Typography  variant="body2" color="white" component="div">
               ( vendorId : {contactData.vendorId} )
              </Typography>
          
            {/* Contact Person Details */}
            <div  >
              <Typography variant="body1" className="mt-3 text-white " component="div" >
              <FaUserAlt /> {' '}
                {editMode ? (
                  <TextField
                    type="text"
                    name="contactPersonName"
                    value={contactData.contactPersonName}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { color: '#000000' } }}
                    style={{backgroundColor:"white"}}
                    
                   
                  />
                ) : (
                  contactData.contactPersonName
                )}
              </Typography>
              <Typography variant="body1"  className="mt-3 text-white" component="div" >
                <FaPhone />{' '}
                {editMode ? (
                  <TextField
                    type="text"
                    name="contactPersonPhoneNumber"
                    value={contactData.contactPersonPhoneNumber}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { color: '#000000' } }}
                    style={{backgroundColor:"white"}}
                  
                    
                  />
                ) : (
                  contactData.contactPersonPhoneNumber
                )}
              </Typography>
              <Typography variant="body1"  className="mt-3 text-white" component="div" >
                <FaEnvelope />{' '}
                {editMode ? (
                  <TextField
                    type="text"
                    name="contactPersonEmailId"
                    value={contactData.contactPersonEmailId}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { color: '#000000' } }}
                    style={{backgroundColor:"white"}}                   
                   
                    
                  />
                ) : (
                  contactData.contactPersonEmailId
                )}
              </Typography>
            </div>
            {/* Edit and Save Buttons */}
            {editMode ? (
              <div className="mt-3 " >
                  <button onClick={handleCancelEdit} className="mx-3 btn btn-sm btn-danger" >
                  Cancel
                </button>
                <button onClick={handleSave} className='mx-2 btn btn-sm btn-success'  >
                  Save
                </button>
              
              </div>
            ) : (
              <div>
              <button onClick={handleEdit} className='mt-3 btn btn-sm btn-warning text-white' >
                Edit
              </button>
              </div>
            )}
      
          </CardContent>
          </Col>
         
 


        </Card>
      




    </>
  );
};

export default Profile;