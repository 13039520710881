import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CorporateFare as CorporateFareIcon, CardMembership as CardMembershipIcon, LocationOn, Email, Phone, AccountCircle, Lock, DateRange, FormatListNumbered, Person } from '@mui/icons-material';
import axios from 'axios';
import { AdminAppBar } from '../Component/AdminAppBar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';





const useStyles = makeStyles({
  card: {
    margin: 50,
    padding:20
  },
  
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    marginBottom: 15,
  },
  icon: {
    marginRight: 10,
  },
  textField: {
    width: '100%',
  },
  selectField: {
    width: '100%', 
  },
 
});



const CompanyCard = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    companyName: '',
    companyId: '',
    clientName: '',
    clientId: '',
    companyAddress: '',
    companyEmailId: '',
    companyPhoneNumber: '',
    contactPersonName: '',
    contactPersonPhoneNumber: '',
    contactPersonEmailId: '',
    stateId: '',
    placeName: '',
    unit: '',
    vendorUsername: '',
    vendorPassword: '',
    vendorContractStartDate: '',
    vendorContractEndDate: '',
    // endDate: ''
  });
  const [errors, setErrors] = useState({});
  const [companyOptions, setCompanyOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    axios.get('http://35.154.135.215:8080/api/vendorMasterProfile/companyNames')
      .then(response => {
        setCompanyOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching company names:', error);
      });
  }, []);

  useEffect(() => {
    axios.get('http://35.154.135.215:8080/api/vendorMasterProfile/vendorNames')
      .then(response => {
        setVendorOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching vendor names:', error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // Handle changes for companyName dropdown
    if (name === 'companyName') {
      axios.get(`http://35.154.135.215:8080/api/vendorMasterProfile/vendorNames`)
        .then(response => {
          const selectedVendor = response.data.find(vendor => vendor.companyName === value);
          console.log("get companyname:", selectedVendor );
          if (selectedVendor) {
            const { companyName, companyId, companyEmailId, companyPhoneNumber, companyAddress,stateId,placeName,unit } = selectedVendor;
            setFormData(prevState => ({
              ...prevState,
              companyName: value,
              companyId,
              companyAddress, 
              companyEmailId,
              companyPhoneNumber,
              stateId,
              placeName,
              unit
            }));
            console.log("vendorId",selectedVendor.companyId)
          }
        })
        .catch(error => {
          console.error('Error fetching vendor details:', error);
        });
    }
  
    // Handle changes for clientName dropdown
    if (name === 'clientName') {
      axios.get(`http://35.154.135.215:8080/api/vendorMasterProfile/companyNames`)
      .then(response => {
       
      const selectedClient= response.data.find(client=> client.companyName === value);
      if (selectedClient) {
        const {  companyId } = selectedClient;
        setFormData(prevState => ({
          ...prevState,
          clientName: value,
          clientId: selectedClient.companyId,
        }));
        console.log("companyId",companyId);
      }
      })
    }
  
    // For other input fields, directly update the formData state
    if (name !== 'companyName' && name !== 'clientName') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;
    const numberPattern = /^[0-9]+$/;

    if (!formData.companyName) newErrors.companyName = 'Select Company';
    if (!formData.clientName) newErrors.clientName= 'Select Client';
    
    if (!formData.contactPersonName) newErrors.contactPersonName = 'Contact Person Name is required';
    if (!emailPattern.test(formData.contactPersonEmailId)) {
      newErrors.contactPersonEmailId = formData.contactPersonEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(formData.contactPersonPhoneNumber)) {
      newErrors.contactPersonPhoneNumber = formData.contactPersonPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
   
    if (!formData.vendorUsername) newErrors.vendorUsername = 'Username is required';
    if (!formData.vendorPassword) newErrors.vendorPassword = 'Password is required';
    if (!formData.vendorContractStartDate) newErrors.vendorContractStartDate = 'Contract Start Date is required';
    if (!formData.vendorContractEndDate) newErrors.vendorContractEndDate = 'Contract End Date is required';

    const selectField = formData.companyName || formData.clientName;
    if (!selectField) {
      newErrors.selectField = 'Please select company and client';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    
    console.log("formData:",formData);
    if (validateForm()) {
    axios.post('http://35.154.135.215:8080/api/vendorMasterProfile/newVendorProfile', formData)
      .then(response => {
        // Assuming the response contains the vendor data you want to store
        if(response.status===200){
        const vendorData = response.data;
         // Store the vendor data in local storage
         localStorage.setItem('vendor', JSON.stringify(vendorData));
        
         window.alert("ID created");
         handleSubmit();

        }
        
       
      })
      .catch(error => {
        console.error('Error saving vendor profile:', error);
      });
    }
  };
  

  const handleSubmit = () => {

   let data = localStorage.getItem('vendor');
   let vendorData = JSON.parse(data);
  //  console.log("data", vendorData.data.vendorId);
   const id = vendorData.data.vendorId;
   console.log(" localstorage vendorId:", id);
   
        // Create company master data object
        const masterData = {
          vendorId: id,
           dateOfCommencement:{
            value:""
           },
           dateOfCompletion: {
            value: ""
          },
          poCopy: {
            value: ""
          },
          epfoAllotment: {
            value: ""
          },
          esicAllotment: {
            value: ""
          },
          pTaxRc: {
            value: ""
          },
          ptEc: {
            value: ""
          },
          lwf: {
            value: ""
          },
          factoryLicence: {
            value: ""
          },
          buildingPlanLayout: {
            value: ""
          },
          stabilityCertificate: {
            value: ""
          },
          concernToEstablishment: {
            value: ""
          },
           concernToOperations: {
            value: ""
          },
          fireAdvisory: {
            value: ""
          },
          fireNoc: {
            value: ""
          },
          environmentClearance: {
            value: ""
          },
          medicalOrHospitalCertificate: {
            value: ""
          },
          apprenticeshipRegistration: {
            value: ""
          },
          neemOrNapsRegistration: {
            value: ""
          },
          clraRc: {
            value: ""
          },
          sAndCeRc: {
            value: ""
          },
          clraLicence: {
            value: ""
          },
          bocwRc: {
            value: ""
          },
          ismwRc: {
            value: ""
          },
          ismwLicence: {
            value: ""
          },
          passaraLicence: {
            value: ""
          },
          fssaiLicence: {
            value: ""
          },


      }
           
      
        // Save company master data
        axios.post(`http://35.154.135.215:8080/api/companyMasterData/newMasterData`,masterData)
          .then(() => {
            window.alert("vendorMasterData created successfully");
            setFormData({
              companyName: '',
              companyId: '',
              clientName: '',
              clientId: '',
              companyAddress: '',
              companyEmailId: '',
              companyPhoneNumber: '',
              contactPersonName: '',
              contactPersonPhoneNumber: '',
              contactPersonEmailId: '',
              stateId: '',
              placeName: '',
              unit: '',
              vendorUsername: '',
              vendorPassword: '',
              vendorContractStartDate: '',
              vendorContractEndDate: '',
              // endDate: ''
            });
          })
          .catch(error => {
            console.error('Error saving company master data:', error);
          });
  };
        return (
          <div>
            <AdminAppBar/>
    <Card className={classes.card} style={{ border:'1px solid gray'}}>
    <Typography className="text-center bg-black text-white p-3 mb-4" variant="h5" >
          Create Vendor
        </Typography>
      <CardContent>
      <Typography className="text-center mb-4 " color="textSecondary" gutterBottom>
        - - - - - - - - - <span className="text-dark"> Company Details </span> - - - - - - - - - - -
        </Typography>
        {/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
        <div className={classes.detail}>
          <CorporateFareIcon className={classes.icon} />
          <Select
            className={classes.selectField}
            label="Vendor Name"
            value={formData.companyName}
            onChange={handleChange}
            name="companyName"
            displayEmpty
            error={!!errors.companyName}
           
          >
            <MenuItem value="" disabled>Select company</MenuItem>
            {Array.isArray(vendorOptions) && vendorOptions.map((vendor, index) => (
  <MenuItem key={index} value={vendor.companyName}>{vendor.companyName + " " + vendor.unit}</MenuItem>
))}

          </Select>
          
          
        </div>
        {errors.selectField && (
  <div className="pb-4 px-5" style={{ color: 'red', fontSize: '14px'}}>
    {errors.selectField}
  </div>
)}
   
   
        {/* <div className={classes.detail}>
          <CardMembershipIcon className={classes.icon} />
          <TextField className={classes.textField} label="Company ID" name="companyId" value={formData.companyId} onChange={handleChange} />
        </div> */}
          

        <div className={classes.detail}>
          <CorporateFareIcon className={classes.icon} />
          <Select
            className={classes.selectField}
            label="Client Name"
            value={formData.clientName}
            onChange={handleChange}
            name="clientName"
            displayEmpty
            error={!!errors.clientName}
          >
            <MenuItem value="" disabled>Select Client</MenuItem>
           {Array.isArray(companyOptions) && companyOptions.map((company, index) => (
      <MenuItem key={index} value={company.companyName}>{company.companyName}</MenuItem>
    ))}
          </Select>
        </div>
        {/* <div className={classes.detail}>
          <CardMembershipIcon className={classes.icon} />
          <TextField className={classes.textField} label="Client ID" name="clientId" value={formData.clientId} onChange={handleChange} />
        </div> */}
        <div className={classes.detail}>
          <LocationOn className={classes.icon} />
          <TextField className={classes.textField} label="Address" name="companyAddress" value={formData.companyAddress} onChange={handleChange} InputProps={{ readOnly: true }}/>
        </div>
        <div className={classes.detail}>
          <Email className={classes.icon} />
          <TextField className={classes.textField} label="Email" name="companyEmailId" value={formData.companyEmailId} onChange={handleChange} InputProps={{ readOnly: true }}/>
        </div>
        <div className={classes.detail}>
          <Phone className={classes.icon} />
          <TextField className={classes.textField} label="Phone Number" name="companyPhoneNumber" value={formData.companyPhoneNumber} onChange={handleChange} type='number' InputProps={{ readOnly: true }} />
        </div>
        {/* <div className={classes.detail}>
        <LocationOn className={classes.icon} />
          <TextField className={classes.textField} label="State Id" name="stateId" value={formData.stateId} onChange={handleChange} type='number' InputProps={{ readOnly: true }}/>
        </div> */}
        {/* <div className={classes.detail}>
          <LocationOn className={classes.icon} />
          <Select
            className={classes.selectField}
            label="State ID"
            value={formData.stateId}
            onChange={handleChange}
            name="stateId"
          >
            {statesInIndia.map((state, index) => (
              <MenuItem key={index} value={state}>{state}</MenuItem>
            ))}
          </Select>
        </div> */}
        <div className={classes.detail}>
          <LocationOn className={classes.icon} />
          <TextField
            className={classes.textField}
            label="Place"
            name="placeName"
            value={formData.placeName}
            onChange={handleChange}
            placeholder="Enter your city"
            InputProps={{ readOnly: true }}
           
          />
        </div>
        {/* <div className={classes.detail}>
          <FormatListNumbered className={classes.icon} />
          <TextField
            className={classes.textField}
            label="Unit"
            type='number'
            placeholder="Enter an integer"
            name="unit"
            value={formData.unit}
            onChange={handleChange}
            InputProps={{ readOnly: true }}
          />
        </div> */}
        {/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
        <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
 - - - - - - - - - <span className="text-dark">Contact Person Details</span> - - - - - - - - - - -
        </Typography>
        <div className={classes.detail}>
          <Person className={classes.icon} />
          <TextField className={classes.textField} label="Contact Person" name="contactPersonName" value={formData.contactPersonName} onChange={handleChange}  error={!!errors.contactPersonName}
            helperText={errors.contactPersonName}/>
        </div>
        <div className={classes.detail}>
          <Phone className={classes.icon} />
          <TextField className={classes.textField} label="Contact Person Phone" name="contactPersonPhoneNumber" value={formData.contactPersonPhoneNumber} onChange={handleChange} error={!!errors.contactPersonPhoneNumber}
              helperText={errors.contactPersonPhoneNumber}/>
        </div>
        <div className={classes.detail}>
          <Email className={classes.icon} />
          <TextField className={classes.textField} label="Contact Person Email" name="contactPersonEmailId" value={formData.contactPersonEmailId} onChange={handleChange} error={!!errors.contactPersonEmailId}
            helperText={errors.contactPersonEmailId}/>
        </div>
        <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
- - - - - - - - - - - <span className="text-dark"> Contract Details </span> - - - - - - - - - - -
        </Typography>
        <div className={classes.detail}>
          <DateRange className={classes.icon} />
          <TextField
            className={classes.textField}
            label="Contract Start Date"
            type="date"
            name="vendorContractStartDate"
            value={formData.vendorContractStartDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.vendorContractStartDate}
            helperText={errors.vendorContractStartDate}
          />
        </div>
        <div className={classes.detail}>
          <DateRange className={classes.icon} />
          <TextField
            className={classes.textField}
            label="Contract End Date"
            type="date"
            name="vendorContractEndDate"
            value={formData.vendorContractEndDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.vendorContractEndDate}
            helperText={errors.vendorContractEndDate}
          />
        </div>
        <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
- - - - - - - - - - - <span className="text-dark"> Login Details </span> - - - - - - - - - - -
        </Typography>

        <div className={classes.detail}>
          <AccountCircle className={classes.icon} />
          <TextField
            className={classes.textField}
            label="Vendor Username"
            name="vendorUsername"
            value={formData.vendorUsername}
            onChange={handleChange}
            style={{ width: '100%' }}
            error={!!errors.vendorUsername}
            helperText={errors.vendorUsername}
          />
        </div>
        {/* <div className={classes.detail}>
          <Lock className={classes.icon} />
          <TextField
            className={classes.textField}
            label="Vendor Password"
            type="password"
            name="vendorPassword"
            value={formData.vendorPassword}
            onChange={handleChange}
            error={!!errors.vendorPassword}
            helperText={errors.vendorPassword}
          />
        </div> */}
        <div className={classes.detail}>
      <Lock className={classes.icon} />
      <FormControl sx={{ width: '100%' }} variant="outlined">

          <TextField
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
            endAdornment:(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
           
            label="Vendor Password"
           
            name="vendorPassword"
            value={formData.vendorPassword}
            onChange={handleChange}
            error={!!errors.vendorPassword}
            helperText={errors.vendorPassword}
          />
        </FormControl>
    
    </div>
        {/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} />

        <div className={classes.detail}>
          <DateRange className={classes.icon} />
          <TextField
            className={classes.textField}
            label="Licence End Day"
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
       {/* <div style={{ display: 'flex', gap: '10px' }}>
    <Button variant="contained" color="primary" style={{ backgroundColor: '#dc3545' }} onClick={handleSave}>Save</Button>
    <Button variant="contained" color="primary" style={{ backgroundColor: '#dc3545' }} onClick={handleSubmit}>Create Database</Button>
    </div>  */}
   <button className="btn btn-warning  text-white w-100 mt-4 "   disabled={!validateForm} onClick={handleSave }>Save</button> 

      </CardContent>
    </Card>
    </div>
  );
};

export default CompanyCard;
