import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VendorAppBar from './Component/VendorAppBar';
import Profile from './Pages/Profile';
import ChangePass from './Pages/ChangePass'

import MasterData from './Component/MasterData';
import Forgot from './Pages/Forgot';

import Login from '../src/Pages/Login';
import Table from './Component/Table';
import AdminCompany from "./Pages/AdminPage";
import AdminVendor from "./Pages/AdminVendor";
import Company from "./Pages/Company";
import { AdminAppBar } from './Component/AdminAppBar';
import { ClientAppBar } from './Component/ClientAppBar';
import Clientdashboard from '../src/Pages/Dashboard/Client/index';
import Vendordashboard from '../src/Pages/Dashboard/Vendor/index';
import Admindashboard from '../src/Pages/Dashboard/Admin/index';
// import  {VendorList}  from './Pages/VendorList';


const App = () => {
 const [isLoggedIn, setIsLoggedIn] = useState(false)

 const handleLogout = () => {
  setIsLoggedIn(false)
 }

  return (
    
      <div>
    
        {/* <ResponsiveAppBar/> */}
        <Routes>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn}/> } />
          <Route path="/createCompany" element={<AdminCompany/>} />
          <Route path="/createVendor" element={<AdminVendor/>} />
          <Route path="/profile" element={<Profile/>} />
          <Route path="/company" element={<Company/>}/>
          <Route path="/changePass" element={<ChangePass/>}/>
          <Route path="/vendor" element={<VendorAppBar/>} />
        
        <Route path="/client" element={<ClientAppBar/>}/>
         
          <Route path="/vendordashboard" element={<Vendordashboard/>}/>
          <Route path="/admindashboard" element={<Admindashboard/>}/>
         {/* <Route path='/vendorslist' element={<VendorList/>}/> */}
          
          <Route path="/vendorMasterData" element={<Table/>}/>
          <Route path="/forgotPassword" element={<Forgot/>}/>
          <Route path="/masterdata" element={<MasterData/>}/>
          <Route path="/admin" element={<AdminAppBar/>}/>

       
        </Routes>
      </div>
   
  );
}

export default App;
