import axios from "axios";
import setupInterceptorsTo from "./AxiosInterceptor";

// const BASE_URL = process.env.REACT_APP_LOCAL_URL;
setupInterceptorsTo(axios);


const api = {
    client:{
        login: (data) => {
            return axios.post('http://35.154.135.215:8080/api/companyMasterProfile/login', data);
        },
      
    },
    admin:{
        login:(data)=>{
            return axios.post('http://35.154.135.215:8080/api/admin/AdminLogin',data);
        },
    },

    vendor:{
        login: (data) => {
            return axios.post('http://35.154.135.215:8080/api/vendorMasterProfile/login', data);
        },
        findvendor: () => {
            return axios.get('http://35.154.135.215:8080/api/vendorMasterProfile/findOne');
        },
        updateOne: (id,data)=>{
            return axios.put(`http://35.154.135.215:8080/api/vendorMasterProfile/updateOne/${id?id:":id"}`,data)
        },
        findOne: (id,data) =>{
            return axios.get(`http://35.154.135.215:8080/api/companyMasterProfile/findOne/${id}`,data);
        },
        master:(data)=>{
            return axios.put('http://35.154.135.215:8080/api/companyMasterData/updateMasterData',data);
        }
    },
};  

export default api;