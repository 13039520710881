import React, { useState, useEffect } from "react";
import {Container, Row, Col,Form,Button, ButtonGroup,} from "react-bootstrap";
import reg2 from "../Images/istock.jpg";
import grclogo from "../Images/GRC-Pulse-Logo.png";
import "./Login.css";
import reg from "../Images/home1.jpg";
import reg3 from "../Images/home3.jpg";
import vendorIcon from '../Images/vendor icon.png';
import adminIcon from '../Images/admin icon.png';
import assesorIcon from '../Images/assesor icon.png';
import clientIcon from '../Images/client icon.png';
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../Services/api";



const Login = ({setIsLoggedIn}) => {
  const [userType, setUserType] = useState("client");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const img = [reg2, reg];
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUserTypeChange = (type) => {
    setUserType(type);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % img.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  const clientvalidationSchema = yup.object({
    companyUsername: yup.string().required("Username is required"),
    companyPassword: yup.string().required("Password is required"),
  });
  const adminvalidationSchema = yup.object({
    adminPhone: yup.string().required("Username is required"),
    adminPassword: yup.string().required("Password is required"),
  });
  const vendorvalidationSchema = yup.object({
    vendorUsername: yup.string().required("username is required"),
    vendorPassword: yup.string().required("password required"),
  })

  const initialValues = {
    companyUsername: "",
    companyPassword: "",
  };
  const initialVendorValues = {
    vendorUsername: "",
    vendorPassword: "",
  };
  const initialAdminValues = {
    adminPhone: "",
    adminPassword: "",
  };


const formik = useFormik({
  initialValues: userType === "client" ? initialValues : userType === "vendor" ? initialVendorValues : initialAdminValues,
  validationSchema: userType === "client" ? clientvalidationSchema : userType === "vendor" ? vendorvalidationSchema : adminvalidationSchema,
    onSubmit: async (values) => {
        // console.log("form values", values);
      try {
        let result;
        if (userType === "client") {
          result = await api.client.login({
            companyUsername: values.companyUsername,
            companyPassword: values.companyPassword,
          });
        }
        else if(userType == 'admin'){
          result = await api.admin.login({
            adminPhone: values.adminPhone,
            adminPassword: values.adminPassword,
          });
        } else {
          result = await api.vendor.login({
            vendorUsername: values.vendorUsername,
            vendorPassword: values.vendorPassword,
          });
        // console.log("entered else", result);
        }
        // console.log("result", result?.data);
  
        let temp = result?.data?.filtterData;
        let temp1 = result?.data;
        let user ={
          id:temp?.id,
          companyName:temp?.name,
          licenseMode: temp?.licenseMode,
          type: temp?.type
        }
        localStorage.setItem(
          // userType === "client" ? "client" : "vendor",
          "user",
          JSON.stringify(user)
        );
        // localStorage.setItem("Token",JSON.stringify(temp?.token))
        localStorage.setItem("Token",temp?.token)
  
        if (userType == 'client' && temp1.message === "Login Successfull") {
          window.alert("login sucessfull");
          navigate("/client");
          setIsLoggedIn(true)
          console.log("inside if", temp1.message);
        }
        else if(userType == 'admin' && temp1.message == 'Login successful....!!!!!!!!'){
          window.alert("admin login successfull");
          navigate("/admin")
        }
         else if(userType == 'vendor' && temp1.message == 'Login Successfull'){
          window.alert("vendor login successfull");
          navigate("/vendor");
        }
        else{
          window.alert('login unsuccessfull, try with correct username and password');
        }
      } catch (error) {
        window.alert("Please fill in the correct credentials!!");
      }
    },
  });
  

  return (
    <Container>
      <Row className="d-flex justify-content-center align-items-center" >
        {/* <Col  md={8}>
          <div style={{ textAlign: "center", position: "relative" }}>
           
            <img 
              src={img[currentImageIndex]}
              width="100%"
              height="500px"
              alt="Login"
              style={{borderRadius:'0% 0% 0% 90%'}}
          
             
            />
            <div className="p-2" style={{position:'absolute',top:'350px',paddingLeft:'20px',paddingTop:'18px'}}>
              <h3 className="text-dark " style={{letterSpacing:'2px',lineHeight:'38px',fontWeight:'bold'}}>A<span style={{fontSize:'45px',boxShadow:'0px 0px 0px 10px rgba(0,0,0,0.1)'}}>A</span>A</h3>
              <h6 className="text-danger">Accurate | Authentic | Actionable</h6>
            </div>
            <div className="p-2" style={{position:'absolute',top:'440px',backgroundColor:'#FFC000',width:'100%'}}>
              <h4 className="text-dark mt-1" style={{letterSpacing:'0.5px',fontWeight:'bold'}}>Governance| Risk | Compliance</h4> 
        <h6 className="text-white" style={{letterSpacing:'0.5px'}}>AI based Automated Assessment Tool for HR Service Providers / Vendors</h6>
        </div> */}
            {/* <div className=" dot-container" style={{marginTop:"30px"}}>
              {img.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${
                    currentImageIndex === index ? "filled" : ""
                  }`}
                ></div>
              ))}
            </div> */}
        
          {/* </div>
        </Col> */}
      
      

        <Col className="d-flex"  xs={12} md={8} >
       
        <section>
          <div style={{ textAlign: "center", paddingBottom: "10px" }}>
            <img src={grclogo} alt="logo" width="20%" height="auto"></img>
          </div>

          {/* <div style={{ textAlign: "center", marginTop: "5px" }}>
            <ButtonGroup
              toggle
              className="mb-2"
              style={{ border: "0.8px solid grey" }}
            >
              <Button
                style={{ width: "100px"}}
                variant={userType === "client" ? "danger" : "white"}
                onClick={() => handleUserTypeChange("client")}
              >
                Client
              </Button>
              <Button
                style={{ width: "95px"}}
                variant={userType === "vendor" ? "danger" : "white"}
                onClick={() => handleUserTypeChange("vendor")}
              >
                Vendor
              </Button>
            </ButtonGroup>
          </div> */}

          <form  onSubmit={formik.handleSubmit} method="post">
            <div className="mt-2 mb-3" style={{ textAlign: "center" }}>
              <TextField
                id="outlined-textarea"
                // label={userType === "client" ? "companyUsername" : "vendorUsername"}
                label={"Username"}
                // placeholder="Enter user name*"
                style={{ width: "70%" }}
                name={userType === "client" ? "companyUsername" : userType === "admin" ? "adminPhone" : "vendorUsername"}
                value={userType === "client" ? formik.values.companyUsername : userType === "admin" ? formik.values.adminPhone : formik.values.vendorUsername}
                onChange={formik.handleChange}
                error={ userType === "client" ? formik.touched.companyUsername && Boolean(formik.errors.companyUsername) :
                  userType === "admin" ? formik.touched.adminPhone && Boolean(formik.errors.adminPhone) :
                  formik.touched.vendorUsername && Boolean(formik.errors.vendorUsername)
           }
           helperText={ userType === "client" ? formik.touched.companyUsername && formik.errors.companyUsername :
                      userType === "admin" ? formik.touched.adminPhone && formik.errors.adminPhone :
                      formik.touched.vendorUsername && formik.errors.vendorUsername
           }
                autoComplete="off"
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <FormControl variant="outlined" style={{ width: "70%" }}>
                {/* <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel> */}
                <TextField
                  id="outlined-adornment-password"
                  // placeholder="Enter password*"
                  type={showPassword ? "text" : "password"}
                      InputProps={{
                  endAdornment:(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),

                      }}
                  label="Password"
                  name={userType === "client" ? "companyPassword" : userType === "admin" ? "adminPassword" : "vendorPassword"}
                  value={userType === "client" ? formik.values.companyPassword : userType === "admin" ? formik.values.adminPassword : formik.values.vendorPassword}
                  onChange={formik.handleChange}
                  error={ userType === "client" ? formik.touched.companyPassword && Boolean(formik.errors.companyPassword) :
                         userType === "admin" ? formik.touched.adminPassword && Boolean(formik.errors.adminPassword) :
                         formik.touched.vendorPassword && Boolean(formik.errors.vendorPassword)
                  }
                  helperText={ userType === "client" ? formik.touched.companyPassword && formik.errors.companyPassword :
                    userType === "admin" ? formik.touched.adminPassword && formik.errors.adminPassword :
                    formik.touched.vendorPassword && formik.errors.vendorPassword
         }
         autoComplete="new-password"
                />
              </FormControl>
            </div>

            <Form.Group
              controlId="formBasicCheckbox"
              className="d-flex justify-content-around mt-2 mb-3"
            >
              {/* <Form.Check
                type="checkbox"
                label="Remember Me"
                style={{ fontSize: "14px" }}
              />
              <Link className="nav-link" to='/forgotpassword' style={{ fontSize: "14px" }}>
                Forgot Password?
              </Link> */}
            </Form.Group> 

            <div style={{ textAlign: "center" }}>
              <button
                className="btn btn-warning"
                type="submit"
                style={{ height: "40px", width: "70%" }}
                disabled={(userType === "client" && 
                (!formik.values.companyUsername || !formik.values.companyPassword )) ||
                (userType === "vendor" && (!formik.values.vendorUsername || !formik.values.vendorPassword )) ||
              (userType==="admin" && (!formik.values.adminPhone|| !formik.values.adminPassword))}
              >
                Login
              </button>
            </div>
          </form>
          </section>
          <div className='vr ' style={{position:'relative'}}>
      <h6 className="p-3 text-white text-center" style={{position:'absolute',backgroundColor:'black', top:'100px',right:'-35px',borderRadius:'50%'}}>Login As</h6>
      </div>  
        </Col>
        <Col xs={12} md={4}>
      
      <section className="p-5"> 
      <ButtonGroup
          toggle
          className="mb-2 d-grid"
         
        >
  <Button className=" text-center p-2"  onClick={() => handleUserTypeChange("admin")}  variant={userType === "admin" ? "warning" : "white"}  >
 <img src={adminIcon} alt="adminIcon" height="40px" width="55px"></img>
<p className='text-center '>Admin</p>
</Button> 
<Button className="text-center p-2 mt-1 mb-1" onClick={() => handleUserTypeChange("client")} variant={userType === "client" ? "warning" : "white"}>
 <img src={clientIcon} alt="clientIcon" height="40px" width="40px"></img>
<p className='text-center '>Client</p>
</Button>
<Button className='  text-center p-2' onClick={() => handleUserTypeChange("vendor")} variant={userType === "vendor" ? "warning" : "white"} >  
<img  src={vendorIcon} alt="vendorIcon" height="40px" width="60px" ></img>
<p className='text-center '>Vendor</p>
</Button>
{/* <Button className='  text-center p-2' onClick={() => handleUserTypeChange("assesor")} variant={userType === "assesor" ? "warning" : "white"} >  
<img  src={assesorIcon} alt="vendorIcon" height="40px" width="60px" ></img>
<p className='text-center '>Assesor</p>
</Button> */}
</ButtonGroup>
  </section> 
 
    </Col>
      
      
      </Row>
    </Container>
  );
};

export default Login;
