import React, { useState , useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Select, MenuItem, Checkbox, FormControlLabel, Button } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import { makeStyles } from '@mui/styles';
import { AccountCircle, Email, Phone, LocationOn, VerifiedUserOutlined, Lock, DateRange, FormatListNumbered,Person} from '@mui/icons-material';
import LayersIcon from '@mui/icons-material/Layers';
import { Divider } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';


import axios from 'axios';
import { AdminAppBar } from '../Component/AdminAppBar';

const useStyles = makeStyles({
  card: {
  
    
    margin: 50,
    padding:20
  },
  
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    marginBottom: 15,
  },
  icon: {
    marginRight: 10,
  },
  textField: {
    width: '100%',
  },
  selectField: {
    width: '100%', 
  },
});

const statesInIndia = ['Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka', 'Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland', 'Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Andaman and Nicobar Islands','Chandigarh','Dadra and Nagar Haveli and Daman and Diu','Delhi','Ladakh','Lakshadweep','Puducherry'];
const licenseOptions = ['Option 1','Option 2','Option 3'];

const CompanyCard = () => {
  const classes = useStyles();
  const [companyData, setCompanyData] = useState(null);
  const [formData, setFormData] = useState({
        companyName: '',
        companyAddress: '',
        companyEmailId: '',
        companyPhoneNumber: '',
        contactPersonName: '',
        contactPersonPhoneNumber: '',
        contactPersonEmailId: '',
        // natureOfBusiness: '',
        stateId: '',
        placeName: '',
        unit: '',
        isClient:false,
        isVendor: false,
        isSubVendor: false,
        companyUsername: '',
        companyPassword: '',
        endDate: '',
        startDate:''
        // licenceMode: '',
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = useState({});
 
 

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'isClient' || name === 'isVendor' || name === 'isSubVendor' ? checked : value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;
    const numberPattern = /^[0-9]+$/;

    if (!formData.companyName) newErrors.companyName = 'Company Name is required';
    if (!formData.companyAddress) newErrors.companyAddress = 'Address is required';
    if (!emailPattern.test(formData.companyEmailId)) {
      newErrors.companyEmailId = formData.companyEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(formData.companyPhoneNumber)) {
      newErrors.companyPhoneNumber = formData.companyPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    if (!formData.contactPersonName) newErrors.contactPersonName = 'Contact Person Name is required';
    if (!emailPattern.test(formData.contactPersonEmailId)) {
      newErrors.contactPersonEmailId = formData.contactPersonEmailId ? 'Invalid email address(ex:text@email.com)' : 'Email address required';
    }
    if (!phonePattern.test(formData.contactPersonPhoneNumber)) {
      newErrors.contactPersonPhoneNumber = formData.contactPersonPhoneNumber ? 'Invalid phone number(only numbers and length should be 10)' : 'Phone number required';
    }
    if (!formData.stateId) newErrors.stateId = 'State is required';
    if (!formData.placeName) newErrors.placeName = 'Place is required';
    if (!formData.unit) {
      newErrors.unit = 'Unit is required';
    }
     else if (!numberPattern.test(formData.unit)) {
      newErrors.unit = 'Unit must be a numeric value';
    }
    if (!formData.companyUsername) newErrors.companyUsername = 'Username is required';
    if (!formData.companyPassword) newErrors.companyPassword = 'Password is required';
    if (!formData.startDate) newErrors.startDate = 'Contract Start Date is required';
    if (!formData.endDate) newErrors.endDate = 'Contract End Date is required';

    const atLeastOneChecked = formData.isClient || formData.isVendor || formData.isSubVendor;
    if (!atLeastOneChecked) {
      newErrors.checkboxes = 'At least one checkbox (Client, Vendor, or Sub Vendor) must be selected';
    }
    const selectField = formData.stateId;
    if (!selectField) {
      newErrors.selectField = 'Please select State';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
 

  const handleSave = () => {
    console.log("clicked");
    if (validateForm()) {
      console.log("Form is valid, making API request");
      axios.post('http://35.154.135.215:8080/api/companyMasterProfile/newCompanyProfile', formData)
        .then(response => {
         if(response.status===200){
          window.alert("company created succesfully")
          setFormData({
            companyName: '',
            companyAddress: '',
            companyEmailId: '',
            companyPhoneNumber: '',
            contactPersonName: '',
            contactPersonPhoneNumber: '',
            contactPersonEmailId: '',
            stateId: '',
            placeName: '',
            unit: '',
            isClient: false,
            isVendor: false,
            isSubVendor: false,
            companyUsername: '',
            companyPassword: '',
            startDate:'',
            endDate: '',
          });
        }
        else if(response.data.message === 'Company Profile with the same username, company name, unit, and selected status already exists'){
          window.alert("Profile exists");
        }
        else if(response.data.message === 'company username already exists'){
          window.alert("company username is already exists");
        }
        })
        .catch(error => {
          
          console.error('Error saving company data:', error);
        });
    }
  };
 
  return (
    <div>
    <AdminAppBar/>
    <Card className={classes.card} style={{ border:'1px solid gray'}}>
    <Typography className="text-center bg-black text-white p-3 mb-4" variant="h5" >
          Create Company
        </Typography>
      
      <CardContent>
        <Typography className="text-center mb-4 " color="textSecondary" gutterBottom>
        - - - - - - - - - <span className="text-dark"> Company Details </span> - - - - - - - - - - -
        </Typography>
        {/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}

           

        <div className={classes.detail}>
          <AccountCircle className={classes.icon} />
          <TextField className={classes.textField} label="Company Name" name="companyName" value={formData.companyName} onChange={handleChange} error={!!errors.companyName}
              helperText={errors.companyName}/>
        </div>
        
        <div className={classes.detail}>
          <LocationOn className={classes.icon} />
          <TextField className={classes.textField} label="Address" name="companyAddress" value={formData.companyAddress} onChange={handleChange}  error={!!errors.companyAddress}
              helperText={errors.companyAddress}/>
        </div>
        <div className={classes.detail}>
          <Email className={classes.icon} />
          <TextField className={classes.textField} label="Email" name="companyEmailId" value={formData.companyEmailId} onChange={handleChange}   error={!!errors.companyEmailId}
              helperText={errors.companyEmailId}/>
        </div>
        <div className={classes.detail}>
          <Phone className={classes.icon} />
          <TextField className={classes.textField} label="Phone Number" name="companyPhoneNumber" value={formData.companyPhoneNumber} onChange={handleChange} error={!!errors.companyPhoneNumber}
              helperText={errors.companyPhoneNumber}/>
        </div> 
        <div className={classes.detail}>
      <FormatListNumbered className={classes.icon} /> 
      <TextField
        className={classes.textField}
        label="Unit"
        placeholder="Enter an integer"
        name="unit"
        value={formData.unit}
        onChange={handleChange}
        error={!!errors.unit}
              helperText={errors.unit}
      />
        </div>
        <div className={classes.detail}>
          <LocationOn className={classes.icon} />
          <Select
            className={classes.selectField}
            label="State ID"
            value={formData.stateId}
            onChange={handleChange}
            name="stateId"
            displayEmpty
            error={!!errors.stateId}
          >
              <MenuItem value="" disabled >Select State</MenuItem>
            {statesInIndia.map((state, index) => (
             
              <MenuItem key={index} value={state}>{state}</MenuItem>
            ))}
          </Select>
         
        </div>
        {errors.selectField && (
  <div className="pb-3 px-5" style={{ color: 'red', fontSize: '14px'}}>
    {errors.selectField}
    </div>
        )}
         <div className={classes.detail}>
          <LocationOn className={classes.icon} />
          <TextField
            className={classes.textField}
          label="Place"
         name="placeName"
         value={formData.placeName}
       onChange={handleChange}
           placeholder="Enter your city"
           error={!!errors.placeName}
           helperText={errors.placeName}
              />
              </div>
        <div className={classes.detail}>
      <VerifiedUserOutlined className={classes.icon} />
      <FormControlLabel
        control={<Checkbox checked={formData.isClient} onChange={handleChange} name="isClient" />}
        label="Is Client"
      />
    </div>
    <div className={classes.detail}>
      <VerifiedUserOutlined className={classes.icon} />
      <FormControlLabel
        control={<Checkbox checked={formData.isVendor} onChange={handleChange} name="isVendor" />}
        label="Is Vendor"
      />
    </div>
    <div className={classes.detail}>
      <VerifiedUserOutlined className={classes.icon} />
      <FormControlLabel
        control={<Checkbox checked={formData.isSubVendor} onChange={handleChange} name="isSubVendor" />}
        label="Is Sub Vendor"
      />
    </div>
     {errors.checkboxes && (
  <div className="px-5" style={{ color: 'red', fontSize: '14px'}}>
    {errors.checkboxes}
  </div>
)} 


    <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
 - - - - - - - - - <span className="text-dark">Contact Person Details</span> - - - - - - - - - - -
        </Typography>
   
        {/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
        <div className={classes.detail}>
          <Person className={classes.icon} />
          <TextField className={classes.textField} label="Contact Person" name="contactPersonName" value={formData.contactPersonName} onChange={handleChange} error={!!errors.contactPersonName}
              helperText={errors.contactPersonName}/>
        </div>
        <div className={classes.detail}>
          <Phone className={classes.icon} />
          <TextField className={classes.textField} label="Contact Person Phone" name="contactPersonPhoneNumber" value={formData.contactPersonPhoneNumber} onChange={handleChange} error={!!errors.contactPersonPhoneNumber}
              helperText={errors.contactPersonPhoneNumber}/>
        </div>
        <div className={classes.detail}>
          <Email className={classes.icon} />
          <TextField className={classes.textField} label="Contact Person Email" name="contactPersonEmailId" value={formData.contactPersonEmailId} onChange={handleChange} error={!!errors.contactPersonEmailId}
              helperText={errors.contactPersonEmailId}/>
        </div>
        {/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
        {/* <div className={classes.detail}>
          <AccountCircle className={classes.icon} />
          <Select
            className={classes.selectField}
            label="Nature of Business"
            value={formData.natureOfBusiness}
            onChange={handleChange}
            name="natureOfBusiness"
          >
            <MenuItem value="Retail">Retail</MenuItem>
            <MenuItem value="Manufacturing">Manufacturing</MenuItem>
          </Select>
        </div> */}
        
       
       {/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}



    {/* <Divider variant="fullWidth" style={{ fontWeight: 'bolder', marginBottom: '10px', backgroundColor: 'rgba(0, 0, 0, 0.87)' }} /> */}
    <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
- - - - - - - - - - - <span className="text-dark"> Contract Details </span> - - - - - - - - - - -
        </Typography>
    <div className={classes.detail}>
      <DateRange className={classes.icon} />
      <TextField
        className={classes.textField}
        label="Contract Start Date"
        type="date"
        name="startDate"
        value={formData.startDate}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors.startDate}
        helperText={errors.startDate}
      />
    </div>

    <div className={classes.detail}>
      <DateRange className={classes.icon} />
      <TextField
        className={classes.textField}
        label="Contract End Date"
        type="date"
        name="endDate"
        value={formData.endDate}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors.endDate}
        helperText={errors.endDate}
      />
    </div>
    <Typography className="text-center mt-4 mb-4" color="textSecondary" gutterBottom>
- - - - - - - - - - - <span className="text-dark"> Login Details </span> - - - - - - - - - - -
        </Typography>
    <div className={classes.detail}>
      <AccountCircle className={classes.icon} />
      <TextField
        className={classes.textField}
        label="Company Username"
        name="companyUsername"
        value={formData.companyUsername}
        onChange={handleChange}
        error={!!errors.companyUsername}
              helperText={errors.companyUsername}
      />
    </div>
    <div className={classes.detail}>
      <Lock className={classes.icon} />
      <FormControl sx={{ width: '100%' }} variant="outlined">
      
        <TextField
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Password"
          name="companyPassword"
          value={formData.companyPassword}
          onChange={handleChange}
          error={!!errors.companyPassword}
          helperText={errors.companyPassword}
        />
      </FormControl>
    </div>
   


   
    {/* <div className={classes.detail}>
      <FormatListNumbered className={classes.icon} />
      <Select
        className={classes.selectField}
        label="License"
        value={formData.licenceMode}
        onChange={handleChange}
        name="licenceMode"
      >
        {licenseOptions.map((option, index) => (
          <MenuItem key={index} value={option}>{option}</MenuItem>
        ))}
      </Select>
    </div> */}


<button className="btn btn-warning  text-white w-100 mt-4 "   disabled={!validateForm} onClick={handleSave }>Save</button> 
  </CardContent>
    </Card>
    </div>
  );
};

export default CompanyCard;

