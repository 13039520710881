import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const CompanyName = () => {
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    fetchCompanyNames();
  }, []);

  const fetchCompanyNames = async () => {
    try {
      const response = await axios.get('http://35.154.135.215:8080/api/companyMasterProfile/comapanyNames');
      setCompanyNames(response.data); 
    } catch (error) {
      console.error('Error fetching company names:', error);
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleEnterClick = () => {
    
  };

  return (
    <Box display="flex" justifyContent="center" marginTop="50px">
      <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
        <Box paddingLeft="30px">
          <Select
            value={selectedCompany}
            onChange={handleCompanyChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Select company' }}
            sx={{ width: '200px' }} 
          >
            <MenuItem value="" disabled>
              Select company
            </MenuItem>
            {companyNames.map((company, index) => (
              <MenuItem key={company.id} value={company.companyName}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Typography variant="body2" color="textSecondary" align="center" marginTop="10px">
          Click Enter after selecting the company
        </Typography>
        <Box display="flex" justifyContent="center" marginTop="10px">
          <Button
            variant="contained"
            onClick={handleEnterClick}
            sx={{
              backgroundColor: 'red',
              '&:hover': {
                backgroundColor: 'darkred',
              },
            }}
          >
            Enter
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CompanyName;
